<template>
  <div class="px-5 lg:px-0">
    <div
      class="w-full lg:w-1/2 mx-auto bg-white my-10 py-5 px-0 lg:px-2 rounded shadow"
    >
      <div class="flex items-center justify-center">
        <div class="h-24 w-24 overflow-hidden">
          <img src="/logos/1.png" class="h-24 w-full object-contain" />
        </div>
      </div>
      <hr class="border-0 border-b border-solid border-gray-300 my-5" />
      <h2 class="font-bold text-center mb-5 text-blackdok">
        Événement présentiel
      </h2>
      <div class="w-full">
        <div class="text-center text-lg font-semibold text-blackdok">
          Bonjour,
        </div>
        <div
          class="w-11/12 lg:w-7/12 text-center mx-auto mt-5 text-blackdok text-lg"
        >
          Nous avons le plaisir de vous inviter <br />à la présentation de
          <span class="font-bold">dok.ma</span>,
          <strong class="whitespace-no-wrap">
            le futur de la e-santé au Maroc.</strong
          >
        </div>
        <div
          class="w-11/12 lg:w-6/12 text-center mx-auto mt-5 text-dokBlue-ultra text-lg font-bold"
        >
          Hotel Adam Park <br /><span class="whitespace-no-wrap"
            >le 18 Novembre 2020 de 15h à 18h</span
          >
        </div>
      </div>
      <hr class="border-0 border-b border-solid border-gray-300 my-5" />
      <div
        id="program"
        class="w-full flex flex-col justify-center items-center"
      >
        <h2 class="font-bold mb-5 text-blackdok">Au programme</h2>
        <div class="flex flex-col">
          <div class="mb-3 flex items-center font-base text-blackdok">
            <i class="far fa-check-square text-dokBlue-ultra mr-2"></i> Suivi
            médical augmenté
          </div>
          <div class="mb-3 flex items-center font-base text-blackdok">
            <i class="far fa-check-square text-dokBlue-ultra mr-2"></i> Dossier
            médical sécurisé
          </div>
          <div class="mb-3 flex items-center font-base text-blackdok">
            <i class="far fa-check-square text-dokBlue-ultra mr-2"></i> Gestion
            de vos agendas et cabinets personnalisé
          </div>
          <div class="mb-3 flex items-center font-base text-blackdok">
            <i class="far fa-check-square text-dokBlue-ultra mr-2"></i> Service
            de téléconsultation vidéo
          </div>
          <div class="mb-3 flex items-center font-base text-blackdok">
            <i class="far fa-check-square text-dokBlue-ultra mr-2"></i> Gestion
            de votre patientèle
          </div>
          <div class="mb-3 flex items-center font-base text-blackdok">
            <i class="far fa-check-square text-dokBlue-ultra mr-2"></i>
            Communication digitale avec vos patients
          </div>
          <!-- <div class="mb-3 flex items-center font-base text-blackdok">
            <i class="far fa-check-square text-dokBlue-ultra mr-2"></i> Gestion de votre
            communication digitale
          </div> -->
          <div class="mb-3 flex items-center font-base text-blackdok">
            <i class="far fa-check-square text-dokBlue-ultra mr-2"></i>
            Ordonnance sécurisée
          </div>
          <div class="flex items-center font-base text-blackdok">
            <i class="far fa-check-square text-dokBlue-ultra mr-2"></i>
            Blockchain privée confidentielle
          </div>
        </div>
        <div class="font-bold text-lg text-blackdok text-center my-5">
          La présentation sera suivie d’un cocktail
        </div>
        <div class="text-blackdok text-sm">
          Toutes les mesures sanitaires liées au Covid-19 seront respectées
          durant l'événement.
        </div>
      </div>
      <hr class="border-0 border-b border-solid border-gray-300 my-5" />
      <div id="intervenants" class="w-8/12 mx-auto">
        <h2 class="font-bold text-center mb-5 text-blackdok">Intervenants</h2>
        <div class="grid gap-6 grid-cols-1 lg:grid-cols-3">
          <div
            class="flex flex-col items-center"
            v-for="(m, i) in persons"
            :key="i"
          >
            <div
              class="h-32 w-32 flex items-center justify-center rounded-full text-white bg-red-400 overflow-hidden"
            >
              <img :src="m.avatar" class="h-32 w-full object-cover" />
            </div>
            <div class="mt-2 flex flex-col text-center">
              <div id="name" class="text-blackdok text-base font-bold">
                {{ m.name }}
              </div>
              <div id="description" class="text-blackdok text-base font-light">
                {{ m.description }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-48 mx-auto my-8">
        <button
          @click="onClick"
          class="bg-dokBlue-ultra border-0 rounded-cu py-3 text-white w-full cursor-pointer font-semibold"
        >
          Je réserve ma place
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      persons: [
        {
          name: "Samuel KRIEF",
          description: "CEO de dok.ma",
          avatar: "/avatars/afc638a9-ee36-42ed-8380-50e202e3359d.jpeg"
        },
        {
          name: "Ali AKAABOUNE",
          description: "Membre du conseil de dok.ma",
          avatar: "/no-avatar.png"
        },
        {
          name: "Farah MAJDI",
          description: "Gynécologue",
          avatar: "/no-avatar.png"
        }
      ]
    };
  },
  methods: {
    onClick: function() {
      this.$router.push({ name: "invitationInscription" });
    }
  }
};
</script>

<style>
button {
}
</style>